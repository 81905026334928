// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-client-menu-styles__clientMenu {
  height: 118px;
  z-index: 98;
  margin-left: 80px;
}

.src-client-menu-styles__clientMenu input.src-client-menu-styles__formControlResize {
  max-width: 100%;
}

.src-client-menu-styles__individualName {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 1.5rem;
}

.src-client-menu-styles__clientType {
  clear: both;
  margin-left: 7rem;
  margin-top: -1.8rem;
  display: flex;
  align-items: center;
}

.src-client-menu-styles__clientType .src-client-menu-styles__cps-icon {
  font-size: 1.6rem !important;
}

.src-client-menu-styles__name {
  display: inline-block;
  width: calc(100% - 7.3rem);
}

.src-client-menu-styles__startVideoChat {
  display: inline-block;
  margin-top: -0.6rem;
  margin-left: 1.6rem;
}
`, "",{"version":3,"sources":["webpack://./src/client-menu.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".clientMenu {\n  height: 118px;\n  z-index: 98;\n  margin-left: 80px;\n}\n\n.clientMenu input.formControlResize {\n  max-width: 100%;\n}\n\n.individualName {\n  overflow-x: auto;\n  white-space: nowrap;\n  padding-bottom: 1.5rem;\n}\n\n.clientType {\n  clear: both;\n  margin-left: 7rem;\n  margin-top: -1.8rem;\n  display: flex;\n  align-items: center;\n}\n\n.clientType .cps-icon {\n  font-size: 1.6rem !important;\n}\n\n.name {\n  display: inline-block;\n  width: calc(100% - 7.3rem);\n}\n\n.startVideoChat {\n  display: inline-block;\n  margin-top: -0.6rem;\n  margin-left: 1.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientMenu": `src-client-menu-styles__clientMenu`,
	"formControlResize": `src-client-menu-styles__formControlResize`,
	"individualName": `src-client-menu-styles__individualName`,
	"clientType": `src-client-menu-styles__clientType`,
	"cps-icon": `src-client-menu-styles__cps-icon`,
	"name": `src-client-menu-styles__name`,
	"startVideoChat": `src-client-menu-styles__startVideoChat`
};
export default ___CSS_LOADER_EXPORT___;
