export function getActiveTab(userRole) {
  if (window.location.hash.indexOf("communications") !== -1) {
    return "communications";
  } else if (window.location.hash.indexOf("resolution-cases") !== -1) {
    return "resolution-cases";
  } else if (window.location.hash.indexOf("task") !== -1 && window.location.hash.indexOf("tax-prep-tasks") === -1) {
    return "tasks";
  } else if (window.location.hash.indexOf("transcripts") !== -1) {
    return "transcripts";
  } else if (window.location.hash.indexOf("files") !== -1) {
    return "files";
  } else if (userRole === "Client" && window.location.hash.indexOf("billing") !== -1) {
    return "payments";
  } else if (window.location.hash.indexOf("notes") !== -1) {
    return "notes";
  } else if (window.location.hash.indexOf("dates") !== -1) {
    return "dates";
  } else if (window.location.hash.indexOf("billing") !== -1) {
    return "billing";
  } else if (window.location.hash.indexOf("time") !== -1) {
    return "time-entries";
  } else if (window.location.hash.indexOf("requests") !== -1) {
    return "requests";
  } else if (window.location.hash.indexOf("tax-prep-tasks") !== -1) {
    return "tax-returns";
  } else {
    return "home";
  }
}
