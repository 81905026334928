import React, { lazy, Suspense, useEffect, useState } from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { getClientDrawer } from "clients-ui!sofe";
import Participants from "./participants.component.js";
import { getActiveTab } from "./tabs.helper.js";

const ClientDrawer = lazy(getClientDrawer);

export function TopNavHeader({ client, hasAccess, loggedInUser, kabob }) {
  const { name, display_name, is_business } = client;
  const activeTab = getActiveTab(loggedInUser.role);
  const [showClientDrawer, setShowClientDrawer] = useState(false);

  useEffect(() => {
    // Drawer should always be closed on home tab
    activeTab === "home" && setShowClientDrawer(false);
  }, [activeTab]);

  return (
    <div className="cps-topnav-secondary__content +tall cp-pt-12 flex justify-between">
      <div className="flex cp-gap-16">
        <div>
          <div className="cp-subheader">{display_name || name}</div>
          <div className="cp-caption cp-color-app-secondary-text">{is_business ? "Business" : "Individual"}</div>
        </div>
        {activeTab !== "home" && (
          <CpButton
            btnType="flat"
            icon="person-details"
            aria-label="View client details"
            className="cp-ml-16"
            onClick={() => setShowClientDrawer(!showClientDrawer)}
          >
            Client Info
          </CpButton>
        )}
      </div>
      <div className="flex cp-gap-16">
        <Participants hasAccess={hasAccess} client={client} />
        {kabob}
      </div>
      <Suspense fallback={null}>
        <ClientDrawer client={client} show={showClientDrawer} onClose={() => setShowClientDrawer(false)} />
      </Suspense>
    </div>
  );
}
