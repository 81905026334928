import React, { useState, useEffect, useRef } from "react";
import { useHasAccess } from "cp-client-auth!sofe";
import { CpIcon } from "canopy-styleguide!sofe";
import { getClientsUsage } from "./clients.resource.js";
import ClientUsageModal from "./client-usage-modal.component";
import { handleError } from "./error";

// ft_crm - Delete this component when removing this toggle
export const ClientNameDropdown = (props) => {
  const { client } = props;
  const [show, setShow] = useState(false);
  const [showClientUsageModal, setShowClientUsageModal] = useState(false);
  const [clientUsage, setClientUsage] = useState({});
  const canEdit = useHasAccess("clients_create_edit");
  const canArchive = useHasAccess("clients_archive");
  const canDelete = useHasAccess("clients_delete");

  const dropdownToggle = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleClickOutside = (e) => {
    if (dropdownToggle.current && !dropdownToggle.current.contains(e.target)) {
      setShow(false);
    }
  };

  function unarchive() {
    getClientsUsage().subscribe((res) => {
      if (res.used + 1 > res.allowed) {
        setShowClientUsageModal(true);
        setClientUsage({
          clientLimit: res.allowed,
          clientsRemaining: res.allowed - res.used,
        });
      } else {
        props.unarchiveClient();
      }
    }, handleError);
  }

  return (
    <div id="clientNameDropdown" className={`cps-dropdown cps-pull-left cps-margin-left-8 ${show ? "cps-open" : ""}`}>
      <div ref={dropdownToggle} className="cps-btn-icon">
        <a className="cps-link cps-margin-top-0" onClick={() => setShow(!show)}>
          <span className="cps-icon cps-icon-down-caret" />
        </a>
      </div>
      <ul className="cps-dropdown-menu" role="menu">
        {canEdit && (
          <li>
            <a onClick={props.showClientEditModal}>
              <CpIcon name="crud-pencil" className="cps-dropdown-menu__icon" />
              Edit
            </a>
          </li>
        )}
        {canArchive && (
          <li>
            <a onClick={client.is_archived ? unarchive : props.archiveClient}>
              <CpIcon name={client.is_archived ? "af-undo" : "crud-archive"} className="cps-dropdown-menu__icon" />
              {client.is_archived ? "Unarchive" : "Archive"}
            </a>
            <ClientUsageModal
              show={showClientUsageModal}
              onClose={() => setShowClientUsageModal(false)}
              clientsRemaining={clientUsage.clientsRemaining}
              clientLimit={clientUsage.clientLimit}
            />
          </li>
        )}
        {canDelete && (
          <li>
            <a onClick={props.showDeleteModal}>
              <CpIcon name="crud-trash-large" className="cps-dropdown-menu__icon" />
              Delete
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
