// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-participants-styles__dropDownSquare {
  cursor: pointer;
}
.src-participants-styles__dropDownSquare:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/participants.styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,UAAU;AACZ","sourcesContent":[".dropDownSquare {\n  cursor: pointer;\n}\n.dropDownSquare:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropDownSquare": `src-participants-styles__dropDownSquare`
};
export default ___CSS_LOADER_EXPORT___;
