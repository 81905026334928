import React, { useState } from "react";
import { useHasAccess } from "cp-client-auth!sofe";
import { CpButton, CpDropdown, CpIcon } from "canopy-styleguide!sofe";
import { getClientsUsage } from "./clients.resource.js";
import ClientUsageModal from "./client-usage-modal.component";
import { handleError } from "./error";

export const NavHeaderKabob = ({ client, onEdit, onArchive, onUnarchive, onDelete, onAddToClientGroup }) => {
  const { is_archived } = client;
  const [showClientUsageModal, setShowClientUsageModal] = useState(false);
  const [clientUsage, setClientUsage] = useState({});

  const canAddToClientGroup = useHasAccess("client_groups_create_edit") && !client.client_groups;
  const canEdit = useHasAccess("clients_create_edit");
  const canArchive = useHasAccess("clients_archive");
  const canDelete = useHasAccess("clients_delete");

  function unarchive() {
    getClientsUsage().subscribe((res) => {
      if (res.used + 1 > res.allowed) {
        setShowClientUsageModal(true);
        setClientUsage({
          clientLimit: res.allowed,
          clientsRemaining: res.allowed - res.used,
        });
      } else {
        onUnarchive();
      }
    }, handleError);
  }

  if (!canEdit && !canArchive && !canDelete && !canAddToClientGroup) {
    return null;
  }

  return (
    <div>
      <CpDropdown
        renderTrigger={({ toggle }) => <CpButton onClick={toggle} icon="misc-kabob" aria-label="Client options" />}
        renderContent={() => (
          <div className="cp-select-list">
            {canAddToClientGroup && (
              <button onClick={onAddToClientGroup}>
                <CpIcon name="misc-client-group" className="cp-mr-8 cp-color-app-icon" />
                Add to client group
              </button>
            )}
            {canEdit && (
              <button onClick={onEdit}>
                <CpIcon name="crud-pencil" className="cp-mr-8 cp-color-app-icon" />
                Edit
              </button>
            )}
            {canArchive && (
              <button onClick={is_archived ? unarchive : onArchive}>
                <CpIcon name="crud-archive" className="cp-mr-8 cp-color-app-icon" />
                {is_archived ? "Unarchive" : "Archive"}
              </button>
            )}
            {canDelete && (
              <button onClick={onDelete}>
                <CpIcon name="crud-trash-large" className="cp-mr-8 cp-color-app-icon" />
                Delete
              </button>
            )}
          </div>
        )}
      />
      <ClientUsageModal
        show={showClientUsageModal}
        onClose={() => setShowClientUsageModal(false)}
        clientsRemaining={clientUsage.clientsRemaining}
        clientLimit={clientUsage.clientLimit}
      />
    </div>
  );
};
