import React from "react";
import { FetchClient } from "./fetch-client.component.js";
import { HashRouter, Route, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import { ErrorBoundary } from "error-logging!sofe";
import "./client-menu";

@ErrorBoundary({ featureName: "client record" })
@hot(module)
export default class Root extends React.Component {
  render() {
    return (
      <HashRouter basename="/">
        <div>
          <Switch>
            <Route path="/task/:taskId/client/:clientId" component={FetchClient} />
            <Route path="(.*)/client/:clientId" component={FetchClient} exact={false} />
            <Route path="(.*)/clients/:clientId" component={FetchClient} exact={false} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}
