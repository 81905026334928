import fetcher, { fetchAsObservable } from "fetcher!sofe";
import { map } from "rxjs/operators";

export function updateClientName(clientId, formData) {
  let url = `/api/clients/${clientId}`;
  let body = {
    clients: formData,
  };
  return promise(url, "PATCH", body);
}

export function updateClientType(clientId, type, oldType) {
  let url = `/api/clients/${clientId}`;
  let body = {
    clients: {
      client_type: type,
    },
  };
  if (type == "client" && oldType != "client") {
    body.clients.client_since = new Date().getTime();
  }
  return promise(url, "PATCH", body);
}

export function archiveClient(clientId) {
  let url = `/api/clients/${clientId}/archive`;
  return promise(url, "POST");
}

export function unarchiveClient(clientId) {
  return fetchAsObservable(`api/clients/${clientId}/unarchive`, { method: "POST" });
}

export function deleteClient(clientId) {
  let url = `/api/clients/${clientId}`;
  return promise(url, "DELETE");
}

function promise(url, method, body) {
  return new Promise((resolve, reject) => {
    fetcher(url, {
      method: method,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response
            .json()
            .then((json) => {
              resolve(json.clients);
            })
            .catch((ex) => {
              reject(ex);
            });
        } else {
          reject();
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
}

export function getTenantTeamMembers() {
  return fetchAsObservable(`/wg/users?role=TeamMember`).pipe(
    map((response) => {
      return response.users;
    })
  );
}

export function modifyClientUsers(clientId, userIds, newUserIds) {
  return fetchAsObservable(`/api/clients/${clientId}/team-members`, {
    method: "PUT",
    body: {
      team_members: userIds,
      notifications: {
        users: newUserIds,
        messages: {
          team_message:
            "Hi there. We’ll be using Canopy to share ideas, gather feedback, and track progress for this client.",
        },
      },
    },
  });
}

export function getClientsUsage() {
  return fetchAsObservable(`api/clients/reached_limit`);
}

export function putRoleAssignments(clientId, body) {
  return fetchAsObservable(`api/clients/${clientId}/assignments`, {
    method: "PUT",
    body,
  });
}
