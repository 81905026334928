export function setRecentClients(client, loggedInUserId) {
  const maxRecentClients = 10;
  const localStorageKey = `${loggedInUserId}-recentClients`;
  const localStorageValue = JSON.parse(localStorage.getItem(localStorageKey) || "[]");
  const { id, name: clientName, display_name } = client;
  const name = display_name || clientName;
  const filteredClients = localStorageValue.filter((c) => c.id !== id);
  localStorage.setItem(
    localStorageKey,
    JSON.stringify([{ id, name }, ...filteredClients].filter((c, idx) => idx < maxRecentClients))
  );
}

export function archiveClientFromLocalStorage(clientToRemove, loggedInUserId) {
  const localStorageKey = `${loggedInUserId}-recentClients`;
  const localStorageValue = JSON.parse(localStorage.getItem(localStorageKey) || "[]");

  localStorage.setItem(localStorageKey, JSON.stringify(localStorageValue.filter((c) => c.id !== clientToRemove)));
}
